import React, {Component} from 'react';
import generate from 'nanoid/generate';
import InputView from '../input-view/';
import Filter from '../filter/';
import './app.css';


const _numbers = '0123456789';
const _lowercase = 'abcdefghijklmnopqrstuvwxyz';
const _uppercase = _lowercase.toUpperCase();
const _symbols = '-_~^$#@';

export default class App extends Component {

  state = {
    stringLength: 8,
    withLowercase: false,
    withUppercase: false,
    withSymbols: false,
    password: '',
    copied: false
  }

  createAlphabet = () => {
    let {withLowercase, withUppercase, withSymbols, stringLength} = this.state;

    let alphabet = _numbers
        + (withLowercase ? _lowercase : '')
        + (withUppercase ? _uppercase : '')
        + (withSymbols ? _symbols : '');
    
    this.hideTooltip();
    this.setState({
      password: generate(alphabet, stringLength),
    })
  }

  changeStringLength = (value) => {
    this.hideTooltip()
    this.setState({
      stringLength: Number(value),
    })
  }

  changeOptionStatus = (option, status) => {
    this.hideTooltip();
    this.setState({
      [option]: status
    })
  }

  showTooltip = () => {
    if (this.state.copied) {
      clearTimeout(this.timeout);
    }

    this.setState({
      copied: true
    });

    this.timeout = setTimeout(this.hideTooltip, 3000);
  }

  hideTooltip = () => {
    this.setState({
      copied: false
    })
    clearTimeout(this.timeout)
  }

  render() {
    const {stringLength, withLowercase, withUppercase, withSymbols, password} = this.state;
    
    return (
      <div className="App">
        <h2>Генератор паролей</h2>
        <InputView 
          generatedString={password}
          onCopyHandler={this.showTooltip}
          copyStatus = {this.state.copied}/>
        <Filter 
          stringLength={stringLength}
          changeStringLength={this.changeStringLength}
          withLowercase={withLowercase}
          withUppercase={withUppercase}
          withSymbols={withSymbols}
          createAlphabet={this.createAlphabet}
          changeOptionStatus={this.changeOptionStatus}/>
      </div>
    )
  }
}