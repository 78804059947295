import React from 'react';

import './filter.css';

const Filter = (props) => {

    const {stringLength, changeStringLength, 
            withLowercase, withSymbols, 
            withUppercase, createAlphabet, changeOptionStatus} = props;
  
    return (
        <div className="filter">
            <div className="filter-range">
                Длинна
                {" "}
                <input type="range" min="6" max="12" onChange={(e) => changeStringLength(e.target.value)} value={stringLength}/>
                {" "}
                {stringLength}
            </div>
            <div className="custom-control custom-checkbox">
                <input className="custom-control-input" type="checkbox" id="num" readOnly checked/>
                <label className="custom-control-label" htmlFor="num"><span></span>Числа (всегда активны)</label>
            </div>
            <div className="custom-control custom-checkbox">
                <input 
                className="custom-control-input"
                type="checkbox" 
                checked={withLowercase}
                id="lower"
                onChange={(e) => changeOptionStatus('withLowercase', e.target.checked)}/>
                <label className="custom-control-label" htmlFor="lower"><span></span>Строчные буквы</label>
            </div>
            <div className="custom-control custom-checkbox">
                <input 
                className="custom-control-input"
                type="checkbox" 
                checked={withUppercase}
                id="upper"
                onChange={(e) => changeOptionStatus('withUppercase', e.target.checked)}/>
                <label className="custom-control-label" htmlFor="upper"><span></span>Прописные буквы</label>
            </div>
            <div className="custom-control custom-checkbox">
                <input 
                className="custom-control-input"
                type="checkbox"
                id="symb"
                checked={withSymbols}
                onChange={(e) => changeOptionStatus('withSymbols', e.target.checked)}/>
                <label className="custom-control-label" htmlFor="symb"><span></span>Символы</label>
            </div>
            <button type="button" className="btn btn-secondary custom-btn" onClick={createAlphabet}>Сгенерировать</button>
        </div>
    )
}

export default Filter;