import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import './tooltip.css';

export default class Tooltip extends React.Component{

    render() {
        const {value, onCopyHandler, status} = this.props;
        const tooltip = status ? <TooltipView /> : null;

        return (
            <CopyToClipboard text={value} onCopy={onCopyHandler}>
                <div className="copy">
                    <span className="copy-body">
                        <i className="fa fa-copy"></i>
                    </span>
                    {tooltip}
                </div>
            </CopyToClipboard>
        )
    }
}

const TooltipView = () => {
    return <span className="copy-tooltip">Скопировано</span>
}