import React from 'react';
import Tooltip from '../tooltip/';
import './input-view.css';

const InputView = ({ generatedString, onCopyHandler, copyStatus, interval }) => {

    const copyBtn = generatedString ? <Tooltip 
                                            onCopyHandler={onCopyHandler} 
                                            value={generatedString} 
                                            status={copyStatus}
                                            interval={interval}/> : null;

    return (
        <div className="input-view" >
            {copyBtn}
            <input readOnly placeholder="Сгенерируйте пароль" value={generatedString} />
        </div>
    )
}

export default InputView;